import React from 'react';

const EventRefundPolicy = () => (
  <div className='eventDetailsRefundPolicy__wrapper'>
    <h3 className='t-paragraph--bold'>Refund Policy</h3>
    <p>
      Free events may be cancelled at any time without restriction. For paid
      events, full or partial refunds due to guest removal, must be requested by
      reaching out to the event contact.
    </p>
  </div>
);

export default EventRefundPolicy;
